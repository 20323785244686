@import 'styles/includes.scss';

.CardListModule {
    background-color: $colorBlack100;
    padding-top: 30px;

    @include media(m) {
        padding-top: 50px;
    }

    &__Title {
        @extend %container;
        @extend %h2;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 14px;

        @include media(m) {
            margin-bottom: 20px;
        }
    }

    &__Text {
        @extend %container-text;
        @extend %body-copy;
        margin: 0 auto;
        text-align: center;
    }

    &__Container {
        @extend %container;
        text-align: center;
        padding-bottom: 53px;

        @include media(m) {
            padding-bottom: 54px;
        }
    }

    &__Inner {
        display: flex;
        flex-flow: column;
        margin-bottom: 24px;
        margin-top: 42px;

        @include media(m) {
            margin-top: 57px;
        }

        @include media(s) {
            flex-flow: row;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        @include media(m) {
            flex-flow: nowrap;
            margin-bottom: 44px;
        }
    }

    &__Button {
        margin-bottom: 54px;
    }
}
