@import 'styles/includes.scss';

.CardNews {
    $root: &;

    width: 100%;
    background: #fff;
    border: 1px solid $colorBlack300;
    transition: all $transition;
    margin-top: 24px;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        display: none;
    }

    @include media(s) {
        width: calc(50% - 12px);
        margin-top: 0;

        &:nth-child(1) {
            margin-right: 12px;
        }

        &:nth-child(2) {
            margin-left: 12px;
        }

        &:last-child {
            width: 100%;
            margin-top: 24px;
            display: flex;
        }
    }

    @include media(m) {
        width: 33%;
        margin-right: 24px;

        &:last-child {
            margin-right: 24px;
            margin-top: 0;
            width: 33%;
            display: block;
        }
    }

    &:hover {
        text-decoration: underline;
        box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.1);
    }

    &:last-child {
        margin-right: 0;
    }

    &__Inner {
        display: block;
        color: $colorBlack;
        text-decoration: none;
        text-align: left;
        padding: 16px 16px 32px;

        @include media(m) {
            padding: 32px 24px 50px;
        }
    }

    &__Label {
        @extend %labels-small;
        display: block;
        color: $colorBlue;
        margin-bottom: 4px;

        @include media(m) {
            font-size: 1.8rem;
            margin-bottom: 10px;
        }
    }

    &__Title {
        @extend %h3;
        margin-bottom: 4px;
        position: relative;
        display: inline-block;
        margin-right: 0;

        @include media(m) {
            margin-right: 20px;
            margin-bottom: 16px;
        }

        @include media(m) {
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 11px;
                right: -20px;
                background-image: url('#{$assetPath}svg/arrow--right-black.svg');
                background-repeat: no-repeat;
                width: 7px;
                height: 12px;
                background-size: contain;
            }
        }
    }

    &__Image {
        height: 180px;
        width: 100%;
        position: relative;

        @include media(s) {
            #{$root}:last-child & {
                height: 100%;
            }
        }

        @include media(m) {
            height: 208px;

            #{$root}:last-child & {
                height: 208px;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            left: 0;
        }
    }

    &__Text {
        @extend %body-copy-small;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include media(s) {
            -webkit-line-clamp: 3;
        }
    }
}
